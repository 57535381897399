import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import Menuo from "../../komponantoj/Menuo";
import Serchbreto from "../../komponantoj/Serchbreto";
import Retpaghotitolo from "../../komponantoj/Retpaghotitolo";

import zamenhof from '../../havajhoj/bildoj/zamenhof.png';

import "./stilo.css";

const datumbazo = require('../../datumoj/lecionoj.json');


function Chefo() {

  useEffect(() => {
    document.title = 'Ĉefo | Kurso de Esperanto';
  }, []);

  const lecionoj = [];

  Object.keys(datumbazo.lecionoj).forEach(function(i) {
    
    const elemento =
      <Link key={ i } to={ "/leciono?numero=" + String(Number(i) + 1)} className="leciono">
        <p>Leciono { Number(i) + 1 }</p>
        <h2>{ datumbazo.lecionoj[i].titolo }</h2>
      </Link>;

    lecionoj.push(elemento);
  });


  return (
    <div id="chefo-pagho">
      <div className="maldekstra-entenilo">
        <Menuo className="menuo" />
      </div>

      <div className="dekstra-entenilo">
        
        <div className="serchobreto-kaj-retaghotitolo">
          <Serchbreto className="serchbreto" />
          <Retpaghotitolo blanka className="retpaghotitolo ligilo-al-nula-pagho" />
        </div>

        <div className="chambro-entenilo">

          <div className="instruisto">
            <img src={ zamenhof } alt="Zamenhof" className="zamenhof" />
          </div>

          <div className="lecionoj">
            { lecionoj }
          </div>

        </div>
        
        
        
      </div>
    </div>
  );
}

export default Chefo;
