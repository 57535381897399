import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import Menuo from "../../komponantoj/Menuo";
import Serchbreto from "../../komponantoj/Serchbreto";
import Retpaghotitolo from "../../komponantoj/Retpaghotitolo";
import Bildobutono from "../../komponantoj/Bildobutono";
import Parolobalono from "../../komponantoj/Parolobalono";

import reen from '../../havajhoj/bildoj/reen.svg';
import { IoCaretBackCircleOutline, IoCaretForwardCircleOutline } from 'react-icons/io5';

import "./stilo.css";

const datumbazo = require('../../datumoj/lecionoj.json');




function Leciono() {

  const parametroj = new URLSearchParams(window.location.search);
  const lecionoNumero = parametroj.get('numero') - 1;

  let nombroDaMomentoj = 20;
  let aktualaSceno = 0;

  const [ aktualaMomento, setAktualaMomento ] = useState(0);

  // kalkulas la nombron da momentoj
  // for (let i in datumbazo.lecionoj[lecionoNumero].scenoj)
  //   nombroDaMomentoj += datumbazo.lecionoj[lecionoNumero].scenoj[i].momentoj.length;

  let bildo = datumbazo.lecionoj[lecionoNumero].scenoj[aktualaSceno].bildo;
  let alternativaTeksto = datumbazo.lecionoj[lecionoNumero].scenoj[aktualaSceno].alternativaTeksto;

  let maldekstrajTekstoj = [];
  let dekstrajTekstoj = [];
  let momentoj = datumbazo.lecionoj[lecionoNumero].scenoj[aktualaSceno].momentoj[aktualaMomento];

  for (let momento of momentoj) {
    const elemento = <Parolobalono key={ momento }>{ momento[1] }</Parolobalono>;
    if (momento[0] === 0) maldekstrajTekstoj.push(elemento);
    else if (momento[0] === 1) dekstrajTekstoj.push(elemento);
  }  

  const lecionoTitolo = datumbazo.lecionoj[lecionoNumero].titolo;


  useEffect(() => {
    document.title = `Leciono ${lecionoNumero + 1}: ${lecionoTitolo} | Kurso de Esperanto`;
  }, []);





  return (
    <div id="leciono-pagho">
      <div className="maldekstra-entenilo">
        <Menuo className="menuo" />
      </div>

      <div className="dekstra-entenilo">
        
        <div className="serchobreto-kaj-retaghotitolo">
          <Serchbreto className="serchbreto" />
          <Retpaghotitolo blanka className="retpaghotitolo ligilo-al-nula-pagho" />
        </div>
        
        <h1 className="klaso-titolo">{ lecionoTitolo }</h1>

        <div className="chambro-entenilo">

          <div className="klaso-entenilo">

            <div className="dialogoj">
              { maldekstrajTekstoj }

              <img 
                src={ require(`../../havajhoj/bildoj/${bildo}`).default }
                alt={ alternativaTeksto }
              />

              { dekstrajTekstoj }
            </div>

            <div className="ekzercoj">

            </div>

            

          </div>

          <div className="malsupra-entenilo">

            <button onClick={() => setAktualaMomento(aktualaMomento > 0 ? aktualaMomento - 1 : aktualaMomento)} className="navigilo">
              <IoCaretBackCircleOutline className="navigilo-bildo kreskebla" />
            </button>

            <button onClick={() => setAktualaMomento(aktualaMomento < nombroDaMomentoj - 1 ? aktualaMomento + 1 : aktualaMomento)} className="navigilo">
              <IoCaretForwardCircleOutline className="navigilo-bildo kreskebla" />
            </button>
            
            <div className="butono-ligilo-al-nula-pagho">
              <Bildobutono ligilo="/chefo" bildo={ reen } alternativaTeksto="Ligilo al ĉefa paĝo" />
            </div>
            
          </div>

        </div>
      </div>
    </div>
  );
}

export default Leciono;
