import React from 'react';

import './stilo.css';

function Parolobalono(props) {

	return (
		<div className="parolobalono">
			<div>
				<p dangerouslySetInnerHTML={{__html: props.children}} />
			</div>
		</div>
	)
}

export default Parolobalono;