import React from 'react';
import styled, { css } from 'styled-components';

import './stilo.css';

const H1 = styled.h1`
	${props => props.blanka && css`
		color: var(--verda-koloro);
	`};
`;

function Retpaghotitolo(props) {

	return (
		<H1 className="retpaghotitolo" blanka={ props.blanka }>Esperanto</H1>
	);

}

export default Retpaghotitolo;