import React from 'react';

import Bildobutono from "../../komponantoj/Bildobutono";

import busho from '../../havajhoj/bildoj/busho.svg';
import domo from '../../havajhoj/bildoj/domo.svg';
import leciono from '../../havajhoj/bildoj/leciono.svg';
import konservilo from '../../havajhoj/bildoj/konservilo.svg';
import agordoj from '../../havajhoj/bildoj/agordoj.svg';

import './stilo.css';

function Menuo() {

	return (
		<div className="menuo">

			<div className="butono-al-nula-pagho-entenilo">
				<Bildobutono ligilo="/" bildo={ busho } alternativaTeksto="Iri al nula paĝo" />
			</div>

			<div className="ceteraj-butonoj-entenilo">
				<Bildobutono ligilo="/chefo" bildo={ domo } alternativaTeksto="Iri al la ĉefa paĝo" />
				<Bildobutono ligilo="/leciono?numero=1" bildo={ leciono } alternativaTeksto="Iri al leciono" />
				{/* <Bildobutono ligilo="/chefo" bildo={ konservilo } alternativaTeksto="Serĉi" /> */}
				<Bildobutono ligilo="/agordoj" bildo={ agordoj } alternativaTeksto="Iri al agordoj" />
			</div>
		</div>
	)
}

export default Menuo;