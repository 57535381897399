import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Nula from './paghoj/Nula';
import Chefo from './paghoj/Chefo';
import Leciono from './paghoj/Leciono';
import Agordoj from './paghoj/Agordoj';

function Kursilo(){
	return (
		<BrowserRouter>
			<Route path="/" exact component={ Nula } />
			<Route path="/chefo" exact component={ Chefo } />
			<Route path="/leciono" exact component={ Leciono } />
			<Route path="/agordoj" exact component={ Agordoj } />
		</BrowserRouter>
	);
}

export default Kursilo;