import React, { useEffect } from 'react'
import { Link } from "react-router-dom";

import Retpaghotitolo from "../../komponantoj/Retpaghotitolo";
import Butono from "../../komponantoj/Butono";

import "./stilo.css";

function Nula() {

  useEffect(() => {
    document.title = 'Kurso de Esperanto';
  }, []);

  return (
    <div id="nula-pagho">
      
      <h2 className="subtitolo">internacia lingvo</h2>
      
      <Retpaghotitolo />

      <div className="butono-al-chefo">
        <Link to="/chefo">
          <Butono>
            KURSO DE ESPERANTO
          </Butono>
        </Link>
      </div>
      
    </div>
  );
}

export default Nula;
