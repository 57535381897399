import React from 'react';

import Bildobutono from "../../komponantoj/Bildobutono";

import lupeo from '../../havajhoj/bildoj/lupeo.svg';

import './stilo.css';


function Serchbreto() {

	return (
		<div className="serchbreto">
			<input type="text" className="enigujo" placeholder="serĉi" />
			<Bildobutono ligilo="/" bildo={ lupeo } alternativaTeksto="Serĉi" className="bildobutono" />
		</div>
	);
	
}

export default Serchbreto;