import React from 'react';

import Kursilo from './kursilo';

import './havajhoj/stiloj/ghenerala.css';


function Baza() {
  return (
    <Kursilo />
  );
}

export default Baza;