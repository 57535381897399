import React from 'react';
import { Link } from 'react-router-dom';

import './stilo.css';

function Bildobutono(props) {

	return (
		<button className="bildobutono">
			<Link to={ props.ligilo }>
				<img src={ props.bildo } alt={ props.alternativaTeksto } className="kreskebla" />
			</Link>
		</button>

	);

}

export default Bildobutono;