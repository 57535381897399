import React from 'react';

import './stilo.css';

function Butono(props) {

	return (
		<div className="butono">
			<button>
				{ props.children }
			</button>
		</div>
	)
}

export default Butono;