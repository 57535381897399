import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import Menuo from "../../komponantoj/Menuo";
import Serchbreto from "../../komponantoj/Serchbreto";
import Retpaghotitolo from "../../komponantoj/Retpaghotitolo";

import "./stilo.css";

const datumbazo = require('../../datumoj/lecionoj.json');


function Agordoj() {

  useEffect(() => {
    document.title = 'Agordoj | Kurso de Esperanto';
  }, []);


  let lecionoj = [];

  for (let i in datumbazo.lecionoj) {
    const elemento = { value: i, label: `Leciono ${i}: ` + datumbazo.lecionoj[i].titolo };
    lecionoj.push(elemento);
  }

  const [ elektitaLeciono, setElektitaLeciono ] = useState(null);

  const traktiShanghon = e => {
    setElektitaLeciono(e);
  }
  



  return (
    <div id="agordoj-pagho">
      <div className="maldekstra-entenilo">
        <Menuo className="menuo" />
      </div>

      <div className="dekstra-entenilo">
        
        <div className="serchobreto-kaj-retaghotitolo">
          <Serchbreto className="serchbreto" />          
          <Retpaghotitolo blanka className="retpaghotitolo ligilo-al-nula-pagho" />
        </div>

        <div className="chambro-entenilo">

          <p>Leciono:</p>
          <Select
            placeholder="Elektu lecionon"
            value={ elektitaLeciono }
            options={ lecionoj }
            onChange={ traktiShanghon }
            style={{ width: 200 }}
          />

        </div>
        
        
        
      </div>
    </div>
  );
}

export default Agordoj;
